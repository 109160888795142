import { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Image, ModalHeader, ModalBody, ModalFooter, Button } from 'react-bootstrap';
import Spin from './Spin';
import $ from 'jquery';

const Home = () => {

    return (
        <Container>
            <Row className='mt-5'>
                <Spin />
            </Row>
        </Container>
    );
}

export default Home;